import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect-logout.component.html',
  styleUrls: ['./redirect-logout.component.scss']
})
export class RedirectLogoutComponent implements OnInit{

  constructor(private route: ActivatedRoute,private router:Router){}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const redirectURL = params['redirectURL'];
      sessionStorage.setItem("post-login-redirect",redirectURL);
      this.router.navigate(['/auth/signin']);
    });
  }

}
