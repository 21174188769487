import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService implements OnDestroy {
  subscriptions: Map<string, Subscription[]> = new Map<string, Subscription[]>();

  add(identifier: string, subscription: Subscription): void {
    if (this.subscriptions?.has(identifier)) {
      this.subscriptions.get(identifier)?.push(subscription);
    } else {
      this.subscriptions.set(identifier, [subscription]);
    }
  }

  unsubscribeAll(identifier: string): void {
    const subscriptions = this.subscriptions?.get(identifier);
    if (subscriptions) {
      for (const subscription of subscriptions) {
        subscription?.unsubscribe();
      }
      this.subscriptions.delete(identifier);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscriptions => {
      for (const subscription of subscriptions) {
        subscription?.unsubscribe();
      }
    });
    this.subscriptions?.clear();
  }
}
