import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, take } from 'rxjs';
import * as authConsts from '../auth-constants';
import { AuthIntegrationService } from 'src/app/shared/services/authentication/auth-integration.service';
import { ToastService } from 'src/app/shared/services/common/toast.service';
import * as authUtils from '../auth-utils';
import { SignupService } from './signup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { CognitoResponseCode } from 'src/app/shared/utilities/constants';
import { LoginLoaderService } from 'src/app/shared/services/common/loader.service';
import { UserAccountStatus, UserAccountType } from 'src/app/shared/enum';
import { getCachedTenantData, updateCSS } from '@laasy/common-layout';
@Injectable({
  providedIn: 'root'
})
export class AuthConnectService {

  private pageConfig: any = { ...authConsts.cPageConfig };
  private pageConfig$: BehaviorSubject<any> = new BehaviorSubject<any>(this.pageConfig);
  customizationApplied$: BehaviorSubject<number> = new BehaviorSubject<number>(new Date().getTime());

  constructor(private readonly authIntegrationService: AuthIntegrationService,
    private toastService: ToastService,
    private signupService: SignupService,
    private readonly authService: AuthenticationService,
    private router: Router,
    private loaderService: LoginLoaderService,
    private route: ActivatedRoute
  ) { }

  setPageData (data: any) {
    this.pageConfig = { ...this.pageConfig, ...data }
    this.pageConfig$.next(this.pageConfig)
  }

  getPageData() {
    return this.pageConfig$.asObservable()
  }

  async checkEmail(typ: string, email: string): Promise<boolean> {
    if (typ == 'signin') this.loaderService.showLoginLoader(true);
    const emailCheck:any = await this.getEmailData(email)
    const eligibilityError = authUtils.userEligibility_check(
      emailCheck?.data,
      email,
      typ
    );
    if (Object.keys(eligibilityError).length != 0) {
      this.toastService.setToast({ ...eligibilityError });
      return false;
    }
    return true;
  }

  setHeadings(pageCount: number) {
    const pageContent = this.signupService.getSignupPageContent()
    if (pageCount == 0)
      return

    const data = {
      heading: pageContent[pageCount - 1]?.heading,
      subheading1: pageContent[pageCount - 1]?.subheading1,
      subheading2: pageContent[pageCount - 1]?.subheading2,
      signupPageCount: pageCount
    };
    this.setPageData (data)
    return data
  }

  async userSignIn(ip_email: string, ip_pass: string) {
    this.loaderService.showLoginLoader(true);
    const response = await this.authService.CognitoUserpoolLogin({
      email: ip_email,
      password: ip_pass
    });
    if (response?.signInUserSession) {
      this.redirectPostLogin(ip_email,response)
      return;
    } else if (response?.code === CognitoResponseCode.UserNotConfirmedException)
      this.toastService.setToast({
        ...authConsts.warning_msg,
        heading: 'Verification Pending',
        text: authUtils.ErrorMsgDict('', 'Verification Pending')
      });
    else if (response?.code === CognitoResponseCode.NotAuthorizedException)
      this.toastService.setToast({
        ...authConsts.error_msg,
        heading: 'Invalid Credentials',
        text: authUtils.ErrorMsgDict('', 'Invalid Credentials')
      });
    else
      this.toastService.setToast({
        ...authConsts.error_msg,
        heading: 'Something went wrong',
        text: authUtils.ErrorMsgDict(ip_email, 'Something went wrong signin')
      });
    this.loaderService.showLoginLoader(false);
  }

  invalidateVerifyEMailCache(){
    this.authIntegrationService.invalidateVerifyEMailCache()
  }

  async getEmailData(email: string): Promise<any> {
    const key = `check-email-${email}`
    const cache = sessionStorage.getItem(key)
    let res = null
    if(!cache){
      res = await this.authIntegrationService.checkUserByEmail(email).toPromise();
      sessionStorage.setItem(key,JSON.stringify(res))
      // clear cache periodically
      const clearCache = setTimeout(()=>{
        this.invalidateVerifyEMailCache()
        clearTimeout(clearCache)
      },1000*60*15)
    }
    else res = JSON.parse(cache)
    return res
  }
  async checkAccount(email: string): Promise<any> {
    const res:any = await this.getEmailData(email)
    const data = res?.data
    return {
      isActive: data?.accountStatus === UserAccountStatus.Active,
      isExist: data?.userExist,
      isPasswordBased: data?.accountType === UserAccountType.Cognito
    }
  }

  redirectPostLogin(email:string,response:any){
    const token = response.signInUserSession.getIdToken().getJwtToken()
    const postLoginRedirect = sessionStorage.getItem("post-login-redirect")
    const defaultProduct = localStorage.getItem("defaultProduct")
    this.authIntegrationService.triggerEventOnLogin(email)
    if (postLoginRedirect) {
      window.location.href = decodeURIComponent(escape(atob(postLoginRedirect)));
      sessionStorage.removeItem("post-login-redirect")
    }
    else if (defaultProduct) window.location.href = defaultProduct
    else this.router.navigate(['/'])

  }

  getSubtenantFromURL(){
    return forkJoin([this.signupService.getSubtenantData().pipe(take(1)),this.route.queryParams.pipe(take(1))]).pipe(map(result => {
      const [res,params] = result
      const preSelectedSubTenant = params['subTenant']
      const subTenant = res.find((x)=>{
        const isNamePresent = x.subTenantName.toLowerCase().includes(preSelectedSubTenant?.toLowerCase())
        const isIdPresent = x.id === preSelectedSubTenant
        return isNamePresent || isIdPresent
      })
      this.updateTenantCustomizationCSS(subTenant)
      return {res:res, subTenant:subTenant}
    }))
  }

  updateTenantCustomizationCSS(data: any): void {
    const logoUrl = data?.logoUrl || data?.logo;
    const masterTenant = getCachedTenantData()
    updateCSS({
      ...masterTenant,
      logo: logoUrl,
      loginBackground: data?.loginBackground,
      color1: data?.color1,
      color2: data?.color2,
      color3: data?.color3
    })
  }

}

