import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { role } from './constants';

export const roleGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthenticationService);
  const currentSession = await authService.GetCurrentSession();
  const isAuthorized = currentSession?.idToken?.payload[role.key] === role.value
  return isAuthorized
};
