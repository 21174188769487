import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { RedirectLogoutComponent } from './modules/common-pages/redirect-logout/redirect-logout.component';
import { UserResolverService } from './modules/auth/service/user-resolver.service';
import { roleGuard } from './modules/tenant-onboarding/role.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
    resolve:[UserResolverService]
  },
  {
    path: 'auth',
    loadChildren: () => import('./../app/modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule),
    canActivate: [AuthGuard],
    resolve:[UserResolverService]
  },
  {
    path: 'privacy',
    redirectTo: 'support/privacy'
  },
  {
    path: 'termsandconditions',
    redirectTo: 'support/termsandconditions'
  },
  {
    path: 'clearcache',
    redirectTo: 'support/clearcache'
  },
  {
    path: 'auth-redirect',
    component: RedirectLogoutComponent
  },
  {
    path: 'support',
    loadChildren: () => import('./modules/common-pages/common-pages.module').then(m => m.CommonPagesModule),
  },
  {
    path: 'tenantOnboarding',
    loadChildren: () => import('./modules/tenant-onboarding/tenant-onboarding.module').then(m => m.TenantOnboardingModule),
    canActivate: [AuthGuard,roleGuard]
  },
  {
    path: '**',
    redirectTo: 'support/page-not-found'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
