import { UserAccountStatus, UserAccountType } from 'src/app/shared/enum';
import * as authConsts from './auth-constants';
import { environment } from 'src/environment/environment';

export function ErrorMsgDict(email: string, key: string): string {
  if (key === 'Account Cancelled')
    return `Membership for account with email ${email} is cancelled. Kindly contact support team.`;
  if (key === 'Account not found')
    return 'There is no account associated with the email address you provided. Try using a different email to log in or create an account.';
  if (key === 'Log in with')
    return `There is already a ${email} account associated with this email address. To log in, select "Continue with ${email}".`;
  if (key === 'Verification Pending') return 'User email is not verified!';
  if (key === 'Success') return 'Your account has been activated. Please log in.'
  if (key === 'Invalid Credentials') return 'Incorrect email or password!';
  if (key === 'Something went wrong signup') return 'Error signing up. Try again after sometime!';
  if (key === 'Something went wrong signin')
    return `Error occured while signing in Account with email ${email}.`;
  if (key === 'Account Exists') return 'An account with this email address already exists. Please go to the login page to access your account. If you’re having trouble accessing your account, press “Forgot password” on the login page.';
  if (key === 'invalidcode') return 'Verification code failure!';
  if (key === 'Something went wrong phoneno')
    return 'Error occurred while updating profile. Please try again in a few minutes.';
  if (key === 'Invalid OTP') return 'Incorrect OTP. Please enter correct OTP.'
  if (key === 'Email OTP Expired') return 'OTP Expired. Please request a new OTP.'
  if (key === 'Account Exists oauth') return `There is already a account associated with this email address. Use Password-Based Login.`
  if ( key === 'Email address not found') return 'This email is not eligible for registration. Please use the email address where we sent your invitation.'
  if (key === 'Account Exists PreFilledForm') return `An account with this ${email} account already exists. Please go to the login page to access your account.`
  return 'Something went wrong. Please Try again after sometime!';
}
export function successMsgDict(): string {
  return 'Your account has been activated. Please log in.'
}

export function userEligibility_check(userCheckResponse: any, email: string, typ: string) {
  //update oauth name
  const SSO = 'Google';
  if (userCheckResponse.userExist) {
    if (userCheckResponse.accountStatus === UserAccountStatus.Deleted) {
      return {
        ...authConsts.error_msg,
        heading: 'Account Cancelled',
        text: ErrorMsgDict(email, 'Account Cancelled')
      };
    } else if (userCheckResponse.accountType === UserAccountType.Cognito && typ == 'signup') return {
      ...authConsts.error_msg,
      heading: 'Account Exists',
      text: ErrorMsgDict(email, 'Account Exists')
    };else if (userCheckResponse.accountType === UserAccountType.Cognito) return {};
    else {
      return {
        ...authConsts.warning_msg,
        heading: `Log in with ${SSO}`,
        text: ErrorMsgDict(SSO, 'Log in with')
      };
    }
  } else if (!userCheckResponse.userExist && userCheckResponse.isEligible && typ == 'signup')
    return {};
  else if (!userCheckResponse.userExist && userCheckResponse.isEligible && typ == 'signin')
    return {
      ...authConsts.warning_msg,
      heading: `Register account`,
      text: `An account with the email ${email} is not registered. Please sign up.`
    };

  if(typ == 'signup') return {
    ...authConsts.error_msg,
    heading: 'Email address not found',
    text: ErrorMsgDict(email, 'Email address not found')
  };
  return {
    ...authConsts.error_msg,
    heading: 'Account not found',
    text: ErrorMsgDict(email, 'Account not found')
  };
}

export function formatPhoneNumber(event: any) {
  const input = event.target as HTMLInputElement;
  const value = input.value.replace(/\D/g, '');
  if (value.length <= 3) input.value = value;
  else if (value.length <= 6) input.value = `${value.slice(0, 3)}-${value.slice(3)}`;
  else input.value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
  return input.value;
}

export function scrolltoTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

export function clearpage(pageConfig: { [key: string]: any }) {
  for (const key in pageConfig) {
    const propertyValue = pageConfig[key];
    if (typeof propertyValue === 'boolean') {
      pageConfig[key] = false;
    } else if (typeof propertyValue === 'string') {
      pageConfig[key] = '';
    }
  }
  return pageConfig;
}

export function clearWalletCache(){
  const apis = ['user/api/userwallet/userWalletLinkedAccounts','user/api/userastra','wallet/api/UserLedger/getWithdrawalHistory','wallet/api/UserLedger/getLifetimeEarning']
  apis.forEach((api:string)=>{
    const cacheKey = btoa(`${environment.apiUri}/${api}`)
    localStorage.removeItem(cacheKey)
    sessionStorage.removeItem(cacheKey)
  })
}
