export enum TravelType {
  Hotels,
  CarRentals,
  ThingsToDo,
  ThemePark,
  Flights
}

export enum FaqGroup {
  Travel,
  dse,
  laasy
}

export enum RouteDisplayName {
  shopping = 'Shopping',
  wellness = 'Wellness',
  dining = 'Dining',
  entertainment = 'Entertainment',
  faqSection = 'Faq section',
  faq = 'FAQ',
  accessTravel = 'Access travel',
  themeParks = 'Theme parks',
  carRental = 'Car rental',
  thingsToDo = 'Things to do',
  hotels = 'Hotels',
  cruises = 'Cruises',
  support = 'Support',
  account = 'Account',
  editprofile = 'Edit profile',
  mydeals = 'My deals',
  mytrips = 'Purchase history',
  termsofuse = 'Terms of Use',
  aboutus = 'About Us',
  cookiespolicy = 'Cookies Policy',
  thirdpartypolicy = 'Third Party Software Policy',
  privacypolicy = 'Privacy Policy',
  licenses = 'Licenses'
}

export enum UserAccountType {
  Cognito = 'cognito',
  Google = 'google'
}

export enum UserAccountStatus {
  Deleted = 'deleted',
  Active = 'active'
}

export enum EditSectionType {
  Email = 'Email',
  Password = 'Password'
}

export enum MonthList {
  Jan = 1,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  Jul,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec
}

export enum TripStatus {
  Confirmed = 0,
  Completed,
  Cancelled
}

export enum DealsCategory {
  Dining = 1,
  Entertainment,
  Shopping,
  Wellness,
  Cruises
}

export enum Policy {
  TermsOfUse = 'TermsOfUse',
  PrivacyPolicy = 'PrivacyPolicy',
  Cookies = 'Cookies',
  ThirdPartyPolicy = 'ThirdPartyPolicy',
  Licenses = 'Licenses'
}

export enum PolicyFileName {
  TermsOfUse = 'TermsOfUse.json',
  PrivacyPolicy = 'PrivacyPolicy.json',
  Cookies = 'Cookies.json',
  ThirdPartyPolicy = 'ThirdPartyPolicy.json',
  Licenses = 'Licenses.json'
}

export enum GroupType {
  Travel = 'Travel',
}

export enum AgreementType {
  TermsAgreement = 'TermsAgreement',
  PolicyAgreement = 'PolicyAgreement'
}

export enum TransferStatus {
  pending = 'Pending',
  processed = 'Processed',
  failed = 'Failed',
  verifying = 'Verifying',
  suspended = 'Suspended',
  authFailed = "Auth Failed",
  canceled = 'Canceled',
}
