<div [ngSwitch]="layoutComponent">
  <div *ngSwitchCase="'app-layout'">
    <l-header (getMobileApp)="moveToAppBanner()"></l-header>
    <div class="canvas" [ngClass]="{'hide':hideNavigation}">
      <router-outlet (activate)="onActivate()"> </router-outlet>
    </div>
  <l-footer></l-footer>
  </div>
  <div *ngSwitchCase="'auth'" [ngClass]="{'hide':hideNavigation}">
    <router-outlet></router-outlet>
  </div>
</div>
