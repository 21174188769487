/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { SignupRequest } from '../../models/authentication/signup';
import { SignInRequest } from '../../models/authentication/signin';
import { ForgetPasswordRequest } from '../../models/authentication/forget-password';
import { UserProfileAttributeUpdateRequest } from '../../models/profile/profile';
import { CognitoResponseCode, nonCognitoFields } from '../../utilities/constants';
import {
  AuthRedirectService,
  LaasyCookieService,
  LoaderService } from '@laasy/common-layout';
import { Router } from '@angular/router';
import { ToastService } from '../common/toast.service';
import * as authUtils from '../../../modules/auth/auth-utils';
import * as authConsts from '../../../modules/auth/auth-constants';
import { LoginLoaderService } from '../common/loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  nonCognitoFields = nonCognitoFields;
  constructor(
    private cookieService:LaasyCookieService,
    private _router: Router,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private readonly router: Router,
    private authRedirectSrvc: AuthRedirectService,
    private loginLoader:LoginLoaderService
  ) {}

  async GetCurrentSession(): Promise<any>{
    try
    {
      this.loaderService.show()
      const response = await Auth.currentSession();
      this.loaderService.hide();
      return response;
    } catch (error: any) {
      this.loaderService.hide()
      return null;
    }
  }

  async GetCurrentUser(): Promise<any> {
    try {
      this.loaderService.show();
      const response = await Auth.currentAuthenticatedUser();
      this.loaderService.hide();
      return response;
    } catch (error: any) {
      this.loaderService.hide();
      return null;
    }
  }

  async CognitoUserpoolLogin(request: SignInRequest) {
    try {
      const response = await Auth.signIn(request.email, request.password);
      return response;
    } catch (error) {
      return error;
    }
  }

  async FederatedAuth(provider: string) {
    try {
      let response;
      if (provider === 'Google') {
        response = await Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Google
        });
      } else if (provider === 'Apple') {
        response = await Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Apple,
        });
      }
      return response;
    } catch (error) {
      return error;
    }
  }

  async IdPAuth() {
    try {
      const response = await Auth.federatedSignIn({ provider: '' as any });
      return response;
    } catch (error) {
      return error;
    }
  }

  // Note: redirect = false when user is authenticated and moved to auth pages like login,signup,forgotpassword
  async Logout(isGlobal: boolean,redirect:boolean = true) {
    try {
      this.loginLoader.showLoginLoader(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const tenant = await this.GetCurrentSession();
      this.cookieService.removeContext();
      this.cookieService.removeUserAPICache();
      authUtils.clearWalletCache()
      await Auth.signOut({global: isGlobal})
      if(redirect)
        this._router.navigateByUrl('/auth/signin');
      return null;
    } catch (error) {
      return error;
    }
  }

  async SignUp(request: SignupRequest) {
    try {
      this.loaderService.show();
      const response = await Auth.signUp({
        username: request.email,
        password: request.password,
        attributes: {
          email: request.email,
          given_name: request.firstName,
          family_name: request.lastName,
          ...(request.phoneNumber ? { phone_number: `+1${request.phoneNumber}` } : {})
        },
        autoSignIn: {
          enabled: true
        }
      });
      this.loaderService.hide();
      return response;
    } catch (error: any) {
      this.loaderService.hide();
      return error;
    }
  }

  async SendPasswordChangeConfirmationCode(email: string) {
    try {
      this.loaderService.show();
      const response = await Auth.forgotPassword(email);
      this.loaderService.hide();
      return "success";
    } catch (error) {
      this.loaderService.hide();
      return error;
    }
  }

  async PasswordChangeVerification(request: ForgetPasswordRequest): Promise<any> {
    try {
      this.loaderService.show();
      const response = await Auth.forgotPasswordSubmit(
        request.email,
        request.verificationCode,
        request.newPassword
      );
      this.loaderService.hide();
      return response;
    } catch (error) {
      this.loaderService.hide();
      return error;
    }
  }

  async UpdatePassword(oldPassword: string, newPassword: string) {
    return await Auth.currentAuthenticatedUser()
      .then(async user => {
        return await Auth.changePassword(user, oldPassword, newPassword)
          .then(res => {
            return res;
          })
          .catch(error => {
            return error;
          });
      })
      .catch(error => {
        return error;
      });
  }

  async DeleteUser() {
    try {
      const result = await Auth.deleteUser();
      return result;
    } catch (error) {
      return error;
    }
  }

  async UpdateUserAttribute(updateAttributeRequest: UserProfileAttributeUpdateRequest) {
    try {
      const user = await this.GetCurrentUser();
      if (!user) return null;
      if (updateAttributeRequest.attribute == 'email') {
        return CognitoResponseCode.Success;
      } else if (updateAttributeRequest.attribute == 'phoneNumber') {
        return await Auth.updateUserAttributes(user, {
          phone_number: `+1${updateAttributeRequest.value}`
        });
      } else if (updateAttributeRequest.attribute == 'firstName') {
        return await Auth.updateUserAttributes(user, {
          given_name: updateAttributeRequest.value
        });
      } else if (updateAttributeRequest.attribute == 'lastName') {
        return await Auth.updateUserAttributes(user, {
          family_name: updateAttributeRequest.value
        });
      } else if (nonCognitoFields.some(x => { return x == updateAttributeRequest.attribute })) {
        return 'Non-Cognito-Field';
      }
      return null;
    } catch (error) {
      return error;
    }
  }

  redirectToProduct(url: string): void {
    this.authRedirectSrvc.startSwitchingProcess(url);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async updatePassword(userProfile:any) {
    const response = await this.PasswordChangeVerification({
      email: userProfile.email,
      verificationCode: userProfile.otp,
      newPassword: userProfile.pass
    });
    if (response.code === CognitoResponseCode.CodeMismatchException) {
      this.toastService.setToast({
        ...authConsts.error_msg,
        heading: 'Incorrect verification code. Try again!',
        text: authUtils.ErrorMsgDict(userProfile.email, 'invalidcode')
      });
    } else if (response === CognitoResponseCode.Success) {
      this.router.navigateByUrl('/');
    } else {
      this.toastService.setToast({
        ...authConsts.error_msg,
        heading: 'Error while changing password',
        text: authUtils.ErrorMsgDict(userProfile.email, '')
      });
    }
  }
}
