import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginLoaderService {
  isLoginLoader = new BehaviorSubject<boolean>(false);

  showLoginLoader(show: boolean) {
    this.isLoginLoader.next(show);
  }
}
