import { Injectable, OnDestroy } from '@angular/core';
import { AnalyticsService } from '@laasy/common-layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { SegmentEvent } from '../../utilities/constants';

@Injectable({
  providedIn: 'root'
})
export class ToastService implements OnDestroy {
  toastSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  intervalId!: NodeJS.Timeout;
  toast$: Observable<any> = this.toastSubject.asObservable();

  constructor(private analyticsService: AnalyticsService){}

  setToast(msg: any) {
    if(msg && (msg.heading || msg.text))
      this.analyticsService.segmentTrackEvent( { ...msg}, SegmentEvent.toast);
    this.toastSubject.next(msg);
    // clear toast after 1 minute to avoid irrelevant toast
    this.intervalId = setInterval(() => {
      this.clearToast();
    }, 60 * 1000);
  }

  clearToast() {
    this.toastSubject.next(null);
    this.clearToastInterval();
  }

  ngOnDestroy() {
    this.clearToastInterval();
  }

  private clearToastInterval() {
    if (this.intervalId)
      clearInterval(this.intervalId);
  }


}
