export const role = {
  key:'custom:Role',
  value:'Admin'
}

export const collectionTypes = {
  baseSys:'global-system',
  sys:'system-tenant-mapped',
  tenant:'tenant',
  userPref:'user-pref'
}
