import { HttpParams } from '@angular/common/http';
import { AddressList } from '../models/addressList';
import { Travel } from '../models/travel';
import { ImageBannerService } from '../services/image-banner.service';
import { UserInfo } from '../models/profile/user-profile';
import { AppConstants } from './constants';

export class Utils {
  public static getLocalStorageValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  public static setLocalStorageValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public static removeTokenFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  public static formatDate(date: Date): string {
    const yyyy = date.getFullYear();
    let mm: number | string = date.getMonth() + 1;
    let dd: number | string = date.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${yyyy}-${mm}-${dd}`;
  }

  public static importFile(file: File, url: string): void {
    const formData: FormData = new FormData();
    formData.append('fileKey', file, file.name);
    const request = new XMLHttpRequest();
    request.open('POST', url);
    request.send(formData);
  }

  public static formatPhoneNumber(input: string): string {
    const cleaned = input.replace(/\D/g, '');
    if (cleaned.length === 10) {
      return `(${cleaned.substring(0, 3)}) ${cleaned.substring(
        3,
        6
      )}-${cleaned.substring(6)}`;
    } else if (
      cleaned.length === 14 &&
      (/^\(\d{3}\) \d{3}-\d{4}$/).exec(cleaned) !== null
    ) {
      return input;
    }
    return input;
  }

  public static getKeyFromEnumValue(enumName: any, value: any): string {
    const index = Object.values(enumName).indexOf(value);
    return Object.keys(enumName)[index];
  }

  public static getKeyFromObjectValue(objectName: any, value: any): string {
    const key = Object.keys(objectName).find((x) => objectName[x] === value);
    return key ?? '';
  }

  private static getTravelCategoryKey(category: string): string {
    const storageKeyPrefix = 'travel_';
    return `${storageKeyPrefix}${category}`;
  }

  public static saveRecentTravelCategory(category: string, value: any): void {
    const storageKey = this.getTravelCategoryKey(category);
    let recentTravelCategory = this.getRecentTravelCategory(category);

    if (category === 'recentLocations') {
      // Remove the location if it already exists
      recentTravelCategory = recentTravelCategory.filter((loc: AddressList) => {
        return (
          loc.coordinates.lat !== value.coordinates.lat ||
          loc.coordinates.lon !== value.coordinates.lon
        );
      });
    } else if (category === 'recentAirports') {
      // Remove the airport if it already exists
      recentTravelCategory = recentTravelCategory.filter((x: Travel) => {
        return (
          x.coordinates.lat !== value.coordinates.lat || x.coordinates.lon !== value.coordinates.lon
        );
      });
    }

    // Add the recent search at the beginning of the array
    recentTravelCategory.unshift(value);

    // Keep only the first 3 searches
    recentTravelCategory = recentTravelCategory.slice(0, 3);

    // Save the updated array to local storage
    localStorage.setItem(storageKey, JSON.stringify(recentTravelCategory));
  }

  public static getRecentTravelCategory(category: string): any[] {
    const storageKey = this.getTravelCategoryKey(category);
    const storedLocations = localStorage.getItem(storageKey);
    return storedLocations ? JSON.parse(storedLocations) : [];
  }

  public static getBackgroundImage(screenName: string, sectionName: string): string {
    if (ImageBannerService.imageBanners.length > 0) {
      const imageUrl =
        ImageBannerService.imageBanners.find(
          x =>
            x.screenName.toLowerCase() === screenName.toLowerCase() &&
            x.sectionName.toLowerCase() === sectionName.toLowerCase()
        )?.imageUrl ?? '';
      return imageUrl;
    }
    return '';
  }

  public static openUriInNewTab(href: string): void {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href
    }).click();
  }

  public static isMobileScreen(): boolean {
    return window.innerWidth < 960;
  }

  public static hexToRgb(hex: string): number[] {
    const bigint = parseInt(hex.slice(1), 16);
    if (hex.length === 7) {
      // If hex is in the form #RRGGBB
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    } else {
      throw new Error('Invalid hex color code');
    }
  }

  public static sortItems(array: any, key: string, reverse: boolean, keyType?:string) {
      return array.sort((a: any, b: any) => {
        let x,y;
        if(keyType === "Date"){
           x = Date.parse(a[key]);
           y = Date.parse(b[key]);
        } else {
          x = parseFloat(a[key])
          y = parseFloat(b[key])
        }

        if (x < y) return reverse ? 1 : -1;
        if (x > y) return reverse ? -1 : 1;
        return 0;
      });
    }
  }

export function objectToParams(obj: any): string {
  let params = new HttpParams();
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      params = params.append(key, obj[key]);
    }
  }
  return params.toString();
}

export const extractUserData = (userDetails: any) => {
  const [firstName, lastName] = userDetails.displayName.split(' ');
  const user: UserInfo = {
    firstName,
    lastName,
    phoneNumber: userDetails.phone,
    zipcode: userDetails.postalCode ?? '',
  };
  return user;
}

export const getBankLogo = (logo: string) => {
  if(logo)
    return 'data:image/png;base64, ' + logo;
  else
    return '/assets/icons/GenericBankIcon.svg';
}

export const getPendingInfoTooltip = (item: any) => {
  let pendingInfoTooltip = '';
  if(item?.affiliate === 'DropShip') {
    pendingInfoTooltip = AppConstants.purchaseCashbackInfo.dropShipPendingInfo;
  } else {
    pendingInfoTooltip  = AppConstants.purchaseCashbackInfo.pendingInfo
  }

  return pendingInfoTooltip;
}
