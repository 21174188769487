import { Component, OnInit } from '@angular/core';
import { MobileAppBannerService } from '../modules/home/service/mobile-app-banner.service';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { CommonLayoutModule,LaasyCookieService } from '@laasy/common-layout';
import { NgClass, NgSwitch, NgSwitchCase } from '@angular/common';
import { LoginLoaderService } from '../shared/services/common/loader.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [CommonLayoutModule, RouterOutlet, NgClass, NgSwitch, NgSwitchCase]
})

export class LayoutComponent implements OnInit {
  layoutComponent: string = 'app-layout';
  hideNavigation = false
  constructor(
    protected readonly mabService: MobileAppBannerService,
    private router: Router,
    private cookieService: LaasyCookieService,
    private loginLoader: LoginLoaderService
  ) {
    this.update_layout();
    this.router.events.subscribe(async (event: any) => {
      if(event instanceof NavigationStart){
        this.hideNavigation = true
      }
      if (event instanceof NavigationEnd) {
        this.update_layout();
        this.hideNavigation = false
        this.loginLoader.showLoginLoader(false)
      }
    })
  }
  update_layout() {
    if (
      (location.pathname.includes('auth') || location.pathname.includes('clearcache')) &&
      !location.pathname.includes('auth-redirect')
    )
      this.layoutComponent = 'auth';
    else this.layoutComponent = 'app-layout';
    if (location.pathname.includes('auth/logout')) this.layoutComponent = 'app-layout';
    const token = this.cookieService.getIDToken();
    if (!token) this.layoutComponent = 'auth'
  }

  async ngOnInit(): Promise<void> {
    this.update_layout();
    this.router.events.subscribe(async (event: any) => {
      if (event instanceof NavigationEnd) {
        this.update_layout();
      }
    })
  }

  onActivate(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  moveToAppBanner() {
    if (location.pathname == '/') {
      this.mabService.intoView.next(null);
    } else {
      this.router.navigate(['/'], {
        queryParams: { showMobileAppBanner: true }
      });
    }
  }
}
