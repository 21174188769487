import { Injectable } from '@angular/core';
import { AuthorizedApiService } from '@laasy/common-layout';
import { UserViewModel } from '../../models/user';
import { catchError, Observable, retry, tap, throwError } from 'rxjs';
import { ApiResponse } from '../../models/apiResponse';
import { SignupSurveyService } from 'src/app/modules/auth/service/signup-survey.service';

@Injectable({
  providedIn: 'root'
})
export class AuthIntegrationService {
  constructor(
    private readonly apiService: AuthorizedApiService,
    private signupSurvey:SignupSurveyService,
  ) {}

  addSignupUser(userRequest: UserViewModel): Observable<ApiResponse> {
    return this.apiService.post<ApiResponse>(
      'user/api/usermanagement/signup',
      userRequest
    ).pipe(tap(async (x:any)=>{
      this.invalidateVerifyEMailCache()
      if(userRequest?.survey) this.signupSurvey.postSurveyResponse(userRequest?.survey)
    }));
  }

  invalidateVerifyEMailCache(){
    const keyToRemove = 'check-email';
    const keysToRemove = [];
    for (let i = 0; i < sessionStorage.length; i++){
      const key = sessionStorage.key(i);
      if (key?.includes(keyToRemove)){
        keysToRemove.push(key);
      }
    }
    for (const key of keysToRemove){
      sessionStorage.removeItem(key);
    }

  }

  checkUserByEmail(email: string): Observable<ApiResponse> {
    const request = {
      email: email
    };
    return this.apiService.post<ApiResponse>(
      `user/api/usermanagement/verifyUserEmail`,
      request
    ).pipe(
      retry({count: 2, delay: 1000}), //delay to retry if tenant cache not present or email is not set by cognito in cookie
      catchError(error => {
        return throwError(()=>error);
      })
    );
  }

  checkUserByUserId(userId: string): Observable<ApiResponse> {
    return this.apiService.get<ApiResponse>(
      `user/api/usermanagement/verifyUserByUserId?userid=${userId}`
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cancelUserMembership(userId: string): Observable<ApiResponse> {
    return this.apiService.delete<ApiResponse>(
      `user/api/account/cancelMembership`
    );
  }

  triggerEventOnLogin(email: string){
    const request = JSON.stringify(
      {
        'eventName': 'user_login',
        'eventData': `{\"email\":\"${email}\"}`
      }
    )
    this.apiService.post<ApiResponse>(`user/api/usermanagement/triggerEvent`,request).toPromise()
  }
}
