export const commonEnvConfig = {
  isHome: true,
  productName:'Home',
  internalRoutes: [
    { title: 'Personal info', url: '/account' },
    { title: 'Purchase history', url: '/account/purchasehistory' },
    { title: 'Saved', url: '/account/saved' },
    { title: 'Notifications', url: '/account/notifications' },
    { title: 'Support', url: '/support' },
    { title: 'Explore ways to earn cashback', url: '/cashback' },
    { title: 'Privacy policy', url: '/privacy' },
    { title: 'Terms and conditions', url: '/termsandconditions' }
  ],
  logglyKey :'8c22b8d0-1ce7-4536-bd0a-d4ad888e8d27',
  idpEnabled: false,
  astraSDK:'',
  astraSDKSandbox: "https://web-sdk-sandbox.astra.finance/v1/initialize.js",
  astraSDKProd: "https://web-sdk.astra.finance/v1/initialize.js",
  whoAmI: "",
  cognitoUserPoolId: '',
  cognitoAppClientId: '',
  aws_cognito_social_providers:[],
  aws_domain:"",
  aws_redirectSignIn:"",
  aws_redirectSignOut:"",
  redirectHomeBase:"",
  refreshTokenInterval:60, //minutes


  //these are sample values
  // whoAmI: "be5f8ba4-ce32-4ff8-87ca-80dc23dee1fc",
  // cognitoUserPoolId: 'us-east-1_RqprQqJZB',
  // cognitoAppClientId: 'sl480pmngnobpbvqm884j00nd',
  // aws_cognito_social_providers:["GOOGLE"],
  // aws_domain:"laasydev.auth.us-east-1.amazoncognito.com",
  // aws_redirectSignIn:"http://localhost:4200/auth/signupredirect",
  // aws_redirectSignOut:"http://localhost:4200/auth/signin",
  // redirectHomeBase:"home.dev.laasy.co",
}
