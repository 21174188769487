import { Injectable } from '@angular/core';
import { ApiResponse, AuthorizedApiService } from '@laasy/common-layout';
import { AgreementType } from 'src/app/shared/enum';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute, Router } from '@angular/router';
import { first, firstValueFrom, map} from 'rxjs';
import { ToastService } from 'src/app/shared/services/common/toast.service';
import * as authConsts from '../auth-constants';
import { subTenantData } from 'src/app/shared/models/subtenant-selection';
import { EmailOTPService } from './email-otp.service';
import { OTPTimer } from 'src/app/shared/utilities/constants';
import { TenantService } from 'src/app/shared/services/tenant/tenant.service';
import { p_empty } from '../auth-constants';
import { SignupSurveyService } from './signup-survey.service';

// We have 3 flags to change flow of signup
// _isSocialLogin, _isSubTenant, _isSelfReg
// This covers 6 possible flows mapped to below variable
// selfReg email based - SignupSelfRegPageCount
// sociallogin (SelfReg / Eligiblity based)- SocialLoginSignupPageCount
// subtenant email based- SubTenantSignupPageCount
// Eligiblity based reg email signup- SignupTenantPageCount
// selfReg+subtenant - SubtenantSignupSelfRegPageCount
// subtenant social login - SubtenantSocialLoginSignupPageCount
@Injectable({
  providedIn: 'root'
})
export class SignupService {
  private _isSocialLogin!:boolean
  private _isSelfReg!:boolean
  private _isSubTenant!:boolean
  private _isAuthCode!: boolean
  private _isSurvey!: boolean


  private signUpPageCount: number = 0;
  private signUpPageCount$: BehaviorSubject<number> = new BehaviorSubject<number>(this.signUpPageCount);
  private triggerSubmit$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  constructor(private signupSurvey:SignupSurveyService,private route: ActivatedRoute,private tenantService:TenantService,private emailOtpService:EmailOTPService,private authService: AuthorizedApiService,private toastService: ToastService, private router:Router) {
    this.tenantService.getTenantByDomain().subscribe(async (data:any)=>{
      this._isSelfReg = data?.hasSelfRegistration
      this._isSubTenant = data?.hasSubTenant
      this._isAuthCode = data?.hasAuthCode
      const survey = await firstValueFrom(this.signupSurvey.getSurvey())
      this._isSurvey = survey.length>0
    })
   }

  async getTerms() {
    try {
      const response = await this.authService.getAndCacheWithLocalAsync<ApiResponse>('tenant/api/tenant/agreements')
      const agreements = response.data;
      let tnc:any,pp:any
      agreements.forEach((agreement: any) => {
        if (agreement.type === AgreementType.TermsAgreement) tnc = agreement;
        else if (agreement.type === AgreementType.PolicyAgreement) pp = agreement;
      });
      return { termsAndConditions: tnc, privacyPolicy: pp };
    }
    catch (error) {
      return { error: error };
    }
  }

  isSelfRegistration(): boolean {
    return this._isSelfReg
  }

  isSubTenant(): boolean {
    return this._isSubTenant
  }

  isAuthCodeEnabled(): boolean {
    return this._isAuthCode;
  }

  showSurvey(): boolean {
    return this._isSurvey
  }

  addItemAtLastEnum(baseEnum:any,item:string):any {
    const count = Object.keys(baseEnum).length/2
    // add at last
    return {...baseEnum,...(this.showSurvey())?{[count]:item,[item]:count}:{}}
  }

  getSignupEnum():any {
    const baseEnum = this.getSignupBaseEnum()
    return this.addItemAtLastEnum(baseEnum,'survey')
  }

  getSignupBaseEnum():any {
    if (this.isSubTenant()) {
      if (this._isSocialLogin) return authConsts.SubtenantSocialLoginSignupPageCount
      if (this.isSelfRegistration()) {
        return authConsts.SubtenantSignupSelfRegPageCount;
      }
      return authConsts.SubTenantSignupPageCount
    }

    if(this.isAuthCodeEnabled()){
      if(this._isSocialLogin) return authConsts.SocialLoginSignupAuthCodePageCount
      return authConsts.SignupSelfRegAuthCodePageCount
    }
    if(this._isSocialLogin) return authConsts.SocialLoginSignupPageCount
    if (this.isSelfRegistration()) return authConsts.SignupSelfRegPageCount;
    return authConsts.SignupTenantPageCount
  }

  getProgressBarCount() {
    if (this.isSubTenant()) {
      if (this._isSocialLogin)
        return authConsts.progressBars.subtenant_socialLogin
      if (this.isSelfRegistration())
        return authConsts.progressBars.subtenant_selfReg_email;
      return authConsts.progressBars.subtenant_email
    }
    if(this.isAuthCodeEnabled()){
      if(this._isSocialLogin) return authConsts.progressBars.authCode_socialLogin
      return authConsts.progressBars.authCode_selfReg_email
    }
    if(this._isSocialLogin)
      return authConsts.progressBars.socialLogin
    if (this.isSelfRegistration())
      return authConsts.progressBars.selfReg_email;
    return authConsts.progressBars.tenant_email
  }

  getSignupPageContent() {
    const baseEnum = this.getSignupPageBaseContent()
    return {...baseEnum,...(this.showSurvey())?{[baseEnum.length]:p_empty}:{}}
  }

  getSignupPageBaseContent() {
    if (this.isSubTenant()) {
      if (this._isSocialLogin) {
        return authConsts.SubTenantSocialLoginSignupPageContent;
      }
      if (this.isSelfRegistration()) return authConsts.SubTenantSelfRegSignupPageContent
      return authConsts.SubTenantSignupPageContent
    }
    if(this.isAuthCodeEnabled()){
      if(this._isSocialLogin) return authConsts.SocialLoginSignupAuthCodePageContent
      return authConsts.SignupAuthCodePageContent
    }
    if (this._isSocialLogin) {
      return authConsts.SocialLoginSignupPageContent;
    }
    if (this.isSelfRegistration()) return authConsts.SelfRegSignupPageContent
    return authConsts.SignupPageContent
  }
  getSignUpPageCount(): number {
    return this.signUpPageCount;
  }

  setSignUpPageCount(signUpPageCount: number): void {
    this.signUpPageCount = signUpPageCount;
    this.signUpPageCount$.next(signUpPageCount);
  }

  getSignUpPageCountObservable(): Observable<number> {
    return this.signUpPageCount$.asObservable();
  }


  getTriggerSubmitObservable(): Observable<any> {
    return this.triggerSubmit$.asObservable();
  }
  setTriggerSubmitObservable(data:number): void {
    this.triggerSubmit$.next(data);
  }


  async onContinue(userProfile:any,triggerNextPage:boolean=false) {
    this.toastService.setToast(null)
    const pages = this.getSignupEnum()
    //page based continue action
    const page = pages[this.signUpPageCount]
    if(page){
      if(page === 'create_account'){
       if(!triggerNextPage){
          this.setTriggerSubmitObservable(authConsts.signupSubmitType.verify_email)
          return
       }
      if(this.isSelfRegistration())  await this.emailOtpService.sendEmailOTP(userProfile.email,userProfile.subtenant)

      }else if(page === 'email_OTP'){
        const isValidOTP = await this.emailOtpService.verifyEmailOTP(userProfile.email,userProfile.otp)
        if(!isValidOTP) return
      }
    }
    let progressBars = this.getProgressBarCount()
    //not showing progress bar for subtenant selection
    if(this.isSubTenant()) progressBars++
    if(this.showSurvey()) progressBars++
    if (this.signUpPageCount < progressBars) {
      this.setSignUpPageCount(this.signUpPageCount+1)
    } else {
      // signup user
      const signupType = (this._isSocialLogin)?authConsts.signupSubmitType.socialLogin:authConsts.signupSubmitType.email
      this.setTriggerSubmitObservable(signupType)
      return
    }

  }

  onBack(): void {
    this.toastService.setToast(null)
    if(this.signUpPageCount === 1 && this._isSocialLogin){
        this.router.navigateByUrl('/auth/signup')
    }
    // subtenant preselect back fix
    this.route.queryParams.pipe(first()).subscribe(params=>{
      const preSelectedSubTenant = params['subTenant']
      if(!preSelectedSubTenant) return

      if(this.signUpPageCount == 2 && !this._isSocialLogin){
        setTimeout(()=>this.setSignUpPageCount(0),100)
      }
      else if(this.signUpPageCount == 2 && this._isSocialLogin)
        this.router.navigate(['/auth/signup'],{ queryParamsHandling: 'preserve' })
    })
    this.setSignUpPageCount(this.signUpPageCount-1)
  }

  setSocialLogin(state:boolean){
    this._isSocialLogin = state
  }

  getSubtenantData():Observable<subTenantData[]> {
    return this.authService.getAndCacheWithLocal<subTenantData[]>(
      'tenant/api/tenant/subTenants',false,24*60*60).pipe(
        map((res: any) => {
          const resp = res.data;
          const data = resp.map((obj: any) => ({
            id: obj.subTenantId,
            tenantId: obj.tenantId,
            subTenantName: obj.name,
            color1: obj.color1,
            color2: obj.color2,
            color3: obj.color3,
            loginBackground: obj.loginBackground,
            signupAvatarImageUrl: obj.signupAvatarImageUrl,
            logoUrl: obj.logoUrl,
            rank: obj.rank,
          }));
          return data;
        })
      )
  }

  getOTPTimer(){
    return this.authService.getAndCacheWithLocal<OTPTimer>('user/api/usermanagement/otpTimeSettings').pipe(map((res:any)=>{
      return res.data
    }))
  }

  validateSignupAuthCode(authCode:string): Observable<boolean>{
    return this.authService.getAndCacheWithLocal(`tenant/api/tenant/validateAuthCode/${authCode}`).pipe(map((x:any)=>x?.data))
  }

}
