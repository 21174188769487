import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpExchangeInterceptor } from './core/interceptor/http.interceptor';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AuthGuard } from './core/guards/auth.guard';
import { CommonLayoutModule } from '@laasy/common-layout';
import { ToastModule } from 'primeng/toast';
import { LoginLoaderService } from './shared/services/common/loader.service';
import { TenantService } from './shared/services/tenant/tenant.service';
import { NotificationService } from './shared/services/common/notification.service';
import { LayoutComponent } from './layout/layout.component';
import { RedirectLogoutComponent } from './modules/common-pages/redirect-logout/redirect-logout.component';
import { environment } from 'src/environment/environment';
import { TenantAdminInterceptor } from './modules/tenant-onboarding/tenant-admin.interceptor';
import { DatePipe } from '@angular/common';

export function fetchDataFactory(apiService: TenantService) {
  return ()=>apiService.getTenantAppConfigPromise()
}

@NgModule({
  declarations: [AppComponent,RedirectLogoutComponent],
  imports: [
    ToastModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonLayoutModule.forRoot(environment),
    LayoutComponent
  ],
  providers: [
    DatePipe,
    NotificationService,
    MessageService,
    HttpClient,
    LoginLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpExchangeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantAdminInterceptor,
      multi: true
    },
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: (primeConfig: PrimeNGConfig) => () => {
        primeConfig.ripple = true;
      },
      deps: [PrimeNGConfig],
      multi: true
    },
    { provide: APP_INITIALIZER, useFactory: fetchDataFactory, deps: [TenantService], multi: true },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
