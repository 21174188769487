import { Injectable } from '@angular/core';
import { AuthorizedApiService,CommonService,LaasyCookieService,getCookieDomain, getHostName, updateCSS, updateEnv, urlProtocol } from '@laasy/common-layout';
import { Observable, catchError, firstValueFrom, map, throwError } from 'rxjs';
import { ApiResponse } from '../../models/apiResponse';
import { FaqGroup } from '../../models/faq';
import { APIResponse } from '../../models/deals/pagedOffers';
import { environment } from 'src/environment/environment';
import { Location } from '@angular/common';
import { Amplify } from 'aws-amplify';
import awsmobile from 'src/aws-exports';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';
import { TenantContextService } from './tenant-context.service';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(private router: Router, private laasyCookieService: LaasyCookieService, private readonly authenticationService: AuthenticationService, private titleService: Title, private authapi: AuthorizedApiService, private location: Location, private readonly apiService: AuthorizedApiService, private commonService: CommonService,private tenantContextService: TenantContextService) { }

  getTenantContext(): Observable<any> {
    return this.commonService.getTenantContext();
  }

  getFaqGroups(): Observable<APIResponse<FaqGroup[]>> {
    return this.apiService.getAndCache<APIResponse<FaqGroup[]>>(
      `tenant/api/tenant/faqGroupTypes`
    );
  }

  getTenantCharities(): Observable<any> {
    return this.apiService.getAndCacheWithLocal<ApiResponse>(
      'tenant/api/home/tenantCharities',
      false,
      2*60*60
    );
  }

  async getTenantAppConfigPromise() {
    try {
      const res: any = await this.authapi.getAndCacheWithLocalAsync(
        `/tenant/api/admin/tenantsbydomain/${window.location.hostname}`
      );
      if (res?.data?.length === 0) return new Error('No Tenant Found')
      this.tenantCustomization(res)
      const currentSession = await this.authenticationService.GetCurrentSession();
      const cognitoClientID = res?.data[0].clientId
      this.commonService.cacheCurrentUser(cognitoClientID)
      return res
    } catch (error) {
      this.router.navigateByUrl('/support/error')
      return error
    }
  }

  getTenantAppConfig(): Observable<any> {
    return this.commonService.getCustomization().pipe(
      map(res => {
        if (res?.data?.length === 0) throw new Error('No Tenant Found')
        const data = this.tenantCustomization(res)
        return data
      }),
      catchError((error: any) => {
        return throwError(() => error)
      })
    );
  }

  tenantCustomization(res: any) {
    const data = res.data[0];
    this.tenantContextService.setTenantName(data.name)
    this.tenantContextService.setSignupWelcomeSubtitle(data.signupWelcomeSubtitle)

    const favIcon: HTMLBaseElement | null = document.querySelector('#appIcon')
    if(data?.favIcon && favIcon)  favIcon!.href = data?.favIcon;

    if (data?.title) this.titleService.setTitle(data?.title);

    if (data?.defaultAppUrl) localStorage.setItem("defaultProduct", urlProtocol(data?.defaultAppUrl));

    let cookieDomain = getCookieDomain(getHostName(this.location))
    if (data.externalDomain) cookieDomain = '.' + data.externalDomain

    environment.idpEnabled = data.hasSSO;
    const context = updateEnv(data, environment, awsmobile, this.location);
    updateCSS(data);
    for (const key in context.environment) {
      // eslint-disable-next-line no-prototype-builtins
      if (environment.hasOwnProperty(key)) (environment as any)[key] = context.environment[key];
    }
    Amplify.configure({ ...context.amplify, cookieStorage: { ...awsmobile['cookieStorage'], domain: cookieDomain } });

    return data
  }

  getTenantByDomain(): Observable<any> {
    return this.commonService.getTenantByDomain().pipe(
      map(res => {
        if (res?.data?.length === 0) throw new Error('No Tenant Found')
        return res?.data[0]
      }),
      catchError((error: any) => {
        return throwError(() => error)
      })
    );
  }

  getUserCashback(){
    return this.commonService.userCashback$
  }
}
