<app-layout></app-layout>
<p-toast #toast position="top-right"></p-toast>
<app-loader></app-loader>
<div class="loader-container" *ngIf="showLoginLoader">
  <div class="login-loader">
    <lottie-player [src]="loginLoaderAnimation" background="transparent" speed="1" direction="1" mode="normal" loop autoplay></lottie-player>
  </div>
  <div class="loading-text">
    <div class="loader-heading">Hang tight…</div>
    <div class="loader-text">We’re finding your account</div>
  </div>
</div>
