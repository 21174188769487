/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { environment } from './environment/environment';

const awsmobile = {
  cookieStorage: {
    domain: '',
    secure: environment.isCookieSecure,
    path: '/',
    expires: 1, //days
    sameSite: 'strict'
  },
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: environment.cognitoUserPoolId,
  aws_user_pools_web_client_id: environment.cognitoAppClientId,
  oauth: {
    domain: environment.aws_domain,
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone'],
    redirectSignIn: environment.aws_redirectSignIn,
    redirectSignOut: environment.aws_redirectSignOut,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: environment.aws_cognito_social_providers,
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS'
    ]
  },
  aws_cognito_verification_mechanisms: ['EMAIL']
};

export default awsmobile;
