import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  toast$: Observable<any> = this.toastSubject.asObservable();

  setToast(msg: any) {
    this.toastSubject.next(msg);
  }
  clearToast(){
    this.setToast(null);
  }
}
