import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { NotificationService } from 'src/app/shared/services/common/notification.service';

@Injectable()
export class TenantAdminInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url.includes('/tenantadmin/')){
          this.notificationService.showError((error?.error?.message ?? error?.error),error?.message)
        }
        return throwError(() => error);
      })
    );
  }
}
