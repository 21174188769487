import { Injectable } from '@angular/core';
import { AuthorizedApiService } from '@laasy/common-layout';
import { ToastService } from 'src/app/shared/services/common/toast.service';
import { environment } from 'src/environment/environment';
import * as authUtils from '../auth-utils';
import * as authConsts from '../auth-constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailOTPService {

  private resendButtonState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private authApi: AuthorizedApiService,private toastService: ToastService) { }

  async sendEmailOTP(email: string, subTenantId: string):Promise<boolean> {
    const payload = { phoneNumber: null, email: email, tenantId: environment.whoAmI, subTenantId: subTenantId }
    try{
      const res:any = await this.authApi.post('/user/api/usermanagement/generateEmailVerificationCode', payload).toPromise()
      return res?.data
    } catch(error:any){
      if(error.error?.error?.message === 'Otp is not expired'){
        return false
      }
      this.toastService.setToast({
        ...authConsts.error_msg,
        heading: 'OTP generation failed',
        text: authUtils.ErrorMsgDict('', 'generic error')
      })
    }
    return false
  }

  async verifyEmailOTP(email: string, otp: string):Promise<boolean> {
    const payload = { phoneNumber: null, email: email, tenantId: environment.whoAmI, verificationCode: otp }
    try{
      const res:any = await this.authApi.post('/user/api/usermanagement/validateEmailVerificationCode', payload).toPromise()
      if(!res?.data){
        this.toastService.setToast({
          ...authConsts.error_msg,
          heading: 'Invalid OTP',
          text: authUtils.ErrorMsgDict('', 'Invalid OTP')
        })
      }
      return res?.data
    } catch(error:any){
      if(error?.error?.error?.message === "Otp has expired"){
        //update resend button
        this.setResendButtonState(true)
        this.toastService.setToast({
          ...authConsts.error_msg,
          heading: 'OTP Expired',
          text: authUtils.ErrorMsgDict('', 'Email OTP Expired')
        })
      } else
      this.toastService.setToast({
        ...authConsts.error_msg,
        heading: 'OTP verification failed',
        text: authUtils.ErrorMsgDict('', 'generic error')
      })
    }
    return false
  }

  setResendButtonState(state:boolean){
    this.resendButtonState$.next(state)
  }

  getResendButtonState(){
    return this.resendButtonState$.asObservable()
  }
}
