import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { LaasyCookieService, LoaderService,HIDE_LOADER } from '@laasy/common-layout';

@Injectable({
  providedIn: 'root'
})
export class HttpExchangeInterceptor implements HttpInterceptor {
  requests = 0;
  authorizedURL = ['account/context', 'user/userCashback']

  constructor(
    private laasyCookieService: LaasyCookieService,
    private readonly loaderService: LoaderService,
    private readonly authenticationService: AuthenticationService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: update request context of each http API call made in homepage to hide loader
    const hidePageLoader = window.location.pathname == '/' || window.location.pathname.includes('account/wallet') || window.location.pathname.includes('account/purchasehistory')

    if(hidePageLoader)  this.loaderService.forceHide()

    if(!request?.context?.has(HIDE_LOADER) && !hidePageLoader){
      this.requests += 1;
      this.loaderService.show();
    }
    const token = this.laasyCookieService.getIDToken();
    if (!token && this.authorizedURL.some((url) => request.url.includes(url))) {
      this.hideLoader()
      return throwError(() => new Error('Request cancelled, Not Authorized'))
    }

    return next
      .handle(request)
      .pipe(
        tap(
          (res: any) => {
            if (res instanceof HttpErrorResponse) {
              if (res.status === 401 || res.status === 403) {
                this.authenticationService.Logout(false);
              } else {
                return of(res);
              }
            }
            return null;
          }
        )
      )
      .pipe(finalize(() => this.hideLoader()));
  }

  hideLoader() {
    this.requests -= 1;
    this.loaderService.hide();
  }
}
