import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveData, Router } from '@angular/router';
import { CommonService } from '@laasy/common-layout';
import { catchError, EMPTY, first, firstValueFrom, forkJoin, from, Observable, switchMap } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { AuthConnectService } from './auth-connect.service';
import { TenantService } from 'src/app/shared/services/tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolverService implements ResolveData {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private authconnect: AuthConnectService,
    private comService: CommonService,
    private tenantService: TenantService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return from(this.authenticationService.GetCurrentSession()).pipe(
      switchMap(currentSession => {
        if (currentSession === null) {
          return EMPTY;
        }

        return from(this.authenticationService.GetCurrentUser()).pipe(
          switchMap(user =>
            from(this.authconnect.checkAccount(user.attributes.email)).pipe(
              switchMap(accountStatus => {
                if (!accountStatus.isExist) {
                  this.router.navigate(['/auth/signin']);
                  return EMPTY;
                }

                return forkJoin([
                  this.comService.getUserContext().pipe(first()),
                  this.comService.getUserCashback().pipe(first()),
                  this.tenantService.getTenantAppConfig().pipe(first())
                ]);
              })
            )
          )
        );
      }),
      catchError(err => {
        console.error('Error occurred:', err);
        this.router.navigate(['/auth/signin']);
        return EMPTY;
      })
    );
  }
}
