export const astraStyle = `
#astra-iframe-overlay{
  z-index: 100;
}

#astra-iframe-container{
  top: 0 !important;
  padding: 10px 0 25px 0;
  height: 100vh !important;
  max-height: 720px;
}
`;