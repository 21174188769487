import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { appInitFailed } from '@laasy/common-layout'
import { enableProdMode } from '@angular/core';
import { environment } from './environment/environment';
import { Amplify, Hub } from "aws-amplify";
import * as authUtils from '../src/app/modules/auth/auth-utils';
import * as authConsts from '../src/app/modules/auth/auth-constants';

sessionStorage.setItem('loaderRequestCount', '0')
sessionStorage.setItem("browserMonitorID",environment.browserMonitorID)

// save current replaceState so we can revert to original later
const replaceState = window.history.replaceState;

// temporarily fix for redirect url having code,error,access_token in
if (window.location.pathname.includes("/paypal-redirect")) {
  window.history.replaceState = () => false;
}

// code from - https://github.com/aws-amplify/amplify-js/issues/10415
// configure auth listener
Hub.listen("auth", (data) => {
  if (data.payload.event === "signIn_failure") {
    if (data.payload.data.message.includes('User+already+exists')){
      sessionStorage.setItem(authConsts.toastSessionKey,JSON.stringify({
        ...authConsts.warning_msg,
        heading: 'Account Exists',
        text: authUtils.ErrorMsgDict('', 'Account Exists oauth')
      }))
    }
    // revert replaceState to original
    window.history.replaceState = replaceState;
  }
});

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  //if API promise not resolved
  .catch(err => {
    appInitFailed(err);
  });
