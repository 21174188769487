import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class TenantContextService {
  tenantName : string = ''
  signupWelcomeSubtitle !: string;

  setTenantName(name: string) {
    this.tenantName = name
  }

  setSignupWelcomeSubtitle(text: string){
    this.signupWelcomeSubtitle = text
  }
}
