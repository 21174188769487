import { Injectable } from '@angular/core';
import { AuthorizedApiService } from '@laasy/common-layout';
import { firstValueFrom, interval, map, of } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SignupSurveyService {

  constructor(private authApi: AuthorizedApiService, private readonly authenticationService: AuthenticationService) { }

  getSurvey() {
    return this.authApi.getAndCacheWithLocal<any>('/user/api/survey', false, 24 * 60 * 60).pipe(map(res => {
      const data = res.data
      const transformedData: any[] = []
      data.forEach((item: any) => {
        if (item.surveyPresentation.where !== 'Onboarding') return

        const selectionLimit = item.surveyPresentation.maxAnswerSelectionLimit
        const _item: any = {
          que_id: item.questionId, question: item.question, que_type: item.questionType,
          surveyId: item.surveyId, systemName: item.systemName
        }
        if (selectionLimit) _item.selectionLimit = selectionLimit
        const _options: any[] = []
        item.surveyOptionDetails.forEach((option: any) => {
          _options.push({ ...option, img: option.imageUrl, isSelected: false })
        })
        transformedData.push({ ..._item, options: _options })
      })
      return transformedData
    }))
  }

  postSurveyResponse(payload: any) {
    const interval$ = interval(500).subscribe(async _ => {
      const currentSession = await this.authenticationService.GetCurrentUser();
      if (currentSession) {
        interval$.unsubscribe()
        await firstValueFrom(this.authApi.post('/user/api/surveyauth/surveyResponse', payload))
      }
    })
  }

}
